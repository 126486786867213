<template>
  <b-modal v-model="modalDimensions" :title=" $t('components.dashboard.product.dimensionsModal.title') "
    content-class="card form-rounded-inputs card-ecart" header-class="card-header" @hidden="fnResetProductDimensions()" cancel-variant="danger"
    size="md" hide-header-close>
    <div class="row">
      <div class="col-3 col-sm-2 text-center ">
        <img  v-image="productInfo.imageUrl" class="img-cover-40 border bg-white" > 
      </div>
      <div class="col-9 col-sm-10 text-uppercase">
        <p class="text-muted text-truncate m-0" v-text=" 'ID: ' + productInfo.id "></p>
        <p class="text-info text-truncate  m-0"> <strong v-text=" productInfo.name "></strong></p>
      </div>
    </div>
    <hr class="my-1">
    <p v-text=" $t('components.dashboard.product.dimensionsModal.message') "> </p>
    <div v-show=" (productInfo.variants.length > 0 && stepDimensions == 'variantList') ">

      <div class="row ml-2">
        <div class="col-12 border-bottom mb-2">
          <div class="row ">
            <div class="col-auto">
              <!-- v-model="selectAll" @click=" fnSelectAll() " -->
              <input type="checkbox" id="selectAllVAriants" v-model="selectAllVariants"
                @click=" fnSelectAllVariants() ">
            </div>
            <div class="col-10">
              <label for="selectAllVAriants" class="text-uppercase"> <strong
                  v-text=" $t('general.options.selectAll') "></strong> </label>
            </div>
          </div>
        </div>
        <div class="col-12" v-for=" (variant,index) in productInfo.variants " :key="index"
          v-show=" variant.name != 'Default Title' ">
          <div class="row">
            <div class="col-auto">
              <input type="checkbox" v-model="variantList" :value="variant.id" :id=" index + 'variant' "
                @change=" fnChangeSelectedVariant() ">
            </div>
            <div class="col-10">
              <label :for=" index + 'variant'" class="text-uppercase "> <strong v-text=" variant.name "></strong>
              </label>
            </div>
          </div>
        </div>
        <!-- <div class="col-auto ml-auto " v-if=" this.productInfo.variants.length > 1 ">
                    <button class=" btn btn-sm btn-primary ml-auto " v-text="$t('general.button.next') " :disabled=" variantList.length == 0 " @click=" stepDimensions = 'dimensionsForm' " > </button>
                </div> -->
      </div>

    </div>
    <div v-show=" stepDimensions == 'dimensionsForm' || this.productInfo.variants.length == 0 ">
      <v-observer tag="form" @submit.prevent="fnUpdateDimensions()" ref="dimensionsForm">
        <div class="row">
          <div class="col-12 ">
            <div class="row">
              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.width') ">
                  <div class="form-group">
                    <label for="" v-text=" $t('general.form.width') "></label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control"
                        v-model=" dimensions.width " :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right " id="unitsWidth"> <strong v-text=" units.length ">
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-6 col-sm-6 ">

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.height') ">
                  <div class="form-group">
                    <label for="" v-text=" $t('general.form.height') "></label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control"
                        v-model=" dimensions.height  " :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsHeight"> <strong v-text=" units.length ">
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>
              <div class="col-6 col-sm-6 ">

                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.length') ">
                  <div class="form-group">
                    <label for="" v-text=" $t('general.form.length') "></label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control"
                        v-model=" dimensions.length  " :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsDepth"> <strong v-text=" units.length ">
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>
              <div class="col-6 col-sm-6 ">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.weight') ">
                  <div class="form-group">
                    <label for="" v-text=" $t('general.form.weight') "></label>
                    <div class="input-group">
                      <input type="number" min="0.1" step="0.1" class="form-control"
                        v-model=" dimensions.weight  " :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right" id="unitsWeight"> <strong v-text=" units.weight ">
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

            </div>
          </div>
          <input type="submit" hidden>
        </div>
      </v-observer>

    </div>

    <template v-slot:modal-footer>
      <div class="d-flex w-100 ">
        <b-button variant="primary" class=" mr-2"
          v-if=" (productInfo.variants.length > 0 && stepDimensions == 'dimensionsForm') "
          @click=" stepDimensions = 'variantList' " v-text=" $t('general.button.back') ">
        </b-button>

        <div class=" ml-auto ">
          <b-button variant="none" class=" mr-2 f-w-900 text-uppercase" @click=" fnResetProductDimensions() "
            v-text=" $t('general.button.close') ">
          </b-button>
          <b-button variant="primary" class=" mr-2" :disabled=" variantList.length == 0 "
            v-if=" (productInfo.variants.length > 0 && stepDimensions == 'variantList') "
            @click=" stepDimensions = 'dimensionsForm' " v-text=" $t('general.button.next') ">
          </b-button>
          <b-button variant="info" class=" mr-2" v-if=" stepDimensions == 'dimensionsForm' "
            @click=" fnUpdateDimensions() " :disabled="sendingData">
            <span v-text=" $t('general.button.update') " v-if=" !sendingData "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </b-button>
        </div>

      </div>
    </template>
  </b-modal>

</template>

<script>
  export default {
    name: "ProductDimensionsModal",
    props: {
      showModalDimensions: {
        type: Boolean,
        default: false,
      },
      productInfo: {
        type: Object,
        required: true,
      },
    },
    watch: {
      showModalDimensions() {
        this.modalDimensions = true;
      },
      productInfo() {
        if (this.productInfo != null && this.productInfo.dimensions) {
          if (this.productInfo.variants.length > 0) {
            let tempVariants = [];
            for (let variant of this.productInfo.variants) {
              if (variant.name != 'Default Title') {
                tempVariants.push(variant);
              }

            }
            this.productInfo.variants = tempVariants;
          }
          if (this.productInfo.variants.length == 0) {
            this.stepDimensions = 'dimensionsForm';
            this.dimensions.width = this.productInfo.dimensions.width || null;
            this.dimensions.height = this.productInfo.dimensions.height || null;
            this.dimensions.length = this.productInfo.dimensions.length || null;
            this.dimensions.weight = this.productInfo.dimensions.weight || null;
          }
        } else {}

      }
    },
    data() {
      return ({
        stepDimensions: 'variantList',
        variantList: [],
        sendingData: false,
        modalDimensions: false,
        dimensions: {
          width: null,
          height: null,
          length: null,
          weight: null,
        },
        units: {
          length: 'cm',
          weight: 'kg',
        },
        selectAllVariants: false,
      })
    },
    methods: {
      async fnUpdateDimensions() {
        if (await this.$refs['dimensionsForm'].validate()) {
          this.sendingData = true;
          if (this.productInfo.variants.length == 0) {
            await axios.put('products/' + this.productInfo.id, {
              dimensions: this.dimensions
            }).then((response) => {
              this.$emit('update-product', response.data);
              this.$toasted.global.infoMessage(this.$t('toasted.info.productDimensionsUpdated'));
              this.fnResetProductDimensions();
            }).catch(error => {});
          } else {

            await axios.put(`products/${this.productInfo.id}/variants`, {
              dimensions: this.dimensions,
              ids: this.variantList
            }).then((response) => {
              this.$emit('update-variants', response.data);
              this.$toasted.global.infoMessage(this.$t('toasted.info.productDimensionsUpdated'));
            }).catch(error => {});

            if (((this.productInfo.dimensions == null) || this.productInfo.dimensions.width == 0)) {
              await axios.put('products/' + this.productInfo.id, {
                dimensions: this.dimensions
              }).then((response) => {
                this.$emit('update-product', response.data);
                this.$toasted.global.infoMessage(this.$t('toasted.info.productDimensionsUpdated'));
              }).catch(error => {});
            }

            this.fnResetProductDimensions();

          }

          this.sendingData = false;
        }
      },

      fnResetProductDimensions() {
        this.modalDimensions = false;
        this.dimensions = {
          width: null,
          height: null,
          length: null,
          weight: null,
        };
        this.variantList = [];
        this.stepDimensions = 'variantList';
        this.selectAllVariants = false;
      },

      fnSelectAllVariants() {
        if (this.variantList.length < this.productInfo.variants.length) {
          this.variantList = [];
          for (let index in this.productInfo.variants) {
            this.variantList.push(this.productInfo.variants[index].id);
          }
        } else {
          this.variantList = [];
        }
      },
      fnChangeSelectedVariant() {
        this.selectAllVariants = this.variantList.length == this.productInfo.variants.length;
      },
    }
  }
</script>

<style>

</style>